<template lang="pug">
.retailer-order(id="orderDetailMain")
  .retailer-order-navigation
    span(v-if="order.retailerReferenceId") {{ `${$t('Retailers.RetailerOrder.referenceId')} #${order.retailerReferenceId}` }}
    span(v-if="role=='admin'") {{ `${$t('Retailers.RetailerOrder.batchId')} #${order.batchId}` }}
  .retailer-order-id {{$t('Retailers.RetailerOrder.orderId')}}: {{ order.landingReferenceId }}

  OrderStatus(:order="order" :userInfo="retailerOrBrandOrAdminInfo" @statusUpdated="statusTransition" @showTrackingInfo="showTrackingInfoModal" @reStatusTransition="reStatusTransition")

  .retailer-order-status-banner.retailer-order-status-banner__block(v-if="(order.status)" :style="{background: bgColor}")
    .columns.retailer-order-status-banner__left-block
      .column.is-narrow.retailer-order-status-banner__icon
        a-icon.icon-tips-style(type="info-circle" theme="filled" :style="{color: buttonColor}")
      div
        .retailer-order-status-banner__title {{ differentStatusHasText.title }}
        .retailer-order-status-banner__details {{ differentStatusHasText.content }}


    .retailer-order-status-banner__button-block(v-if = "order.status && differentStatusHasText.buttonList && differentStatusHasText.buttonList.length !== 0")
      .button-main-style(v-for="(list, index) in differentStatusHasText.buttonList" :key="index")
        button.button.retailer-settings__saved-addresses-button.button-new-color(v-if="list.isAccept" @click="openAcceptMessage(list.status)" :style="{background: buttonColor}") {{ list.name }}
        button.button.retailer-settings__saved-addresses-button.button-new-color(v-else-if="list.isEdit" @click="openEditAllProductModal(order, order.status.id, buttonColor)" :style="{background: buttonColor}" :disabled="list.canNotClick") {{ list.name }}
        button.button.retailer-settings__saved-addresses-button.button-new-color(v-else-if="list.isAddShip" @click="openAddShipped(order, list.status, buttonColor)" :style="{background: buttonColor}" :disabled="list.canNotClick") {{ list.name }}
        button.button.retailer-settings__saved-addresses-button.button-new-color(v-else-if="list.isShowAdvice" @click="openPaymentAdvice(order, order.status.id, buttonColor)" :style="{background: buttonColor}" :disabled="list.canNotClick") {{ list.name }}
        button.button.retailer-settings__saved-addresses-button.button-new-color(v-else-if="list.isDelete" @click="statusTransition(order.status.id, list.status)" :style="{background: buttonColor}" :disabled="list.canNotClick") {{ list.name }}
        button.button.retailer-settings__saved-addresses-button.button-new-color(v-else-if="list.isShipped" @click="statusTransition(order.status.id, list.status)" :style="{background: buttonColor}" :disabled="list.canNotClick") {{ list.name }}
        button.button.retailer-settings__saved-addresses-button.button-new-color(v-else @click="statusTransition(order.status.id, list.status, list.isDecline)" :style="{background: buttonColor}") {{ list.name }}


  .columns
    .column.is-two-thirds.retailer-order-table__container
      .columns.is-multiline
        .column.is-12
          .retailer-order-table.table-border-b-none
            .retailer-order-table__top
              p.retailer-order-table__name {{$t('Retailers.RetailerOrder.products')}}
              p.retailer-order-table__edit(v-if="!isRetailer && (order && order.status && order.status.id === orderStatus.pending && !order.declined)" @click="openEditDialog")
                a-icon(v-if="!(isRetailer && this.order.version === '2')" type="edit")
            table.table.is-fullwidth
              thead
                tr
                  th.retailer-order-table__head(style="width: 18%") {{$t('Retailers.RetailerOrder.sKU')}}
                  th.retailer-order-table__head(width="20%") Name
                  th.retailer-order-table__head {{$t('Retailers.RetailerOrder.quantity')}}
                  th.retailer-order-table__head {{$t('Retailers.RetailerOrder.testers')}}
                  th.retailer-order-table__head {{$t('Retailers.RetailerOrder.mSRP')}}
                  th.retailer-order-table__head(width="20%") {{$t('Retailers.RetailerOrder.unitPrice')}}
                  th.retailer-order-table__head(:class="{'alignTextCenter' : alignCenter}") {{$t('Retailers.RetailerOrder.amount')}}
                  th.retailer-order-table__head(v-if="isProductEditable")
              tbody
                tr(v-for="(item, index) in purchaseDetails")
                  td.retailer-order-table__cell(:style="{color: `${item.noteType && item.noteType.id === 'oos' ? '#bfbfbf' : '#262626'}!important`}") {{item.product && item.product.sku}}
                    p(v-if="item.adjusterByClientType" class="edit-params-product" :style="{color: item.textColor,marginTop: '12px'}") {{ `${item.adjusterByClientType.name} Edit` || ""}}
                  td.retailer-order-table__cell(:style="{color: `${item.noteType && item.noteType.id === 'oos' ? '#bfbfbf' : '#262626'}!important`}") {{item.product && item.product.name}}
                  td.retailer-order-table__cell(:style="{color: `${item.noteType && item.noteType.id === 'oos' ? '#bfbfbf' : '#262626'}!important`}") {{item.quantity}}
                    p(v-if="item.adjusterByClientType" class="edit-params-product" :style="{display: 'flex', alignItems: 'center', marginTop: '12px',color: item.textColor}") {{ item.adjustedQuantity || 0}}
                      a-tooltip(v-if="item.noteType && Object.keys(item.noteType).length !== 0" placement="right" :title="item.noteType.name")
                        a-icon(v-if="item.noteType && Object.keys(item.noteType).length !== 0" type="info-circle" :style="{fontSize: '12px', marginLeft: '5px', color: '#bfbfbf'}")
                  td.retailer-order-table__cell(:style="{color: `${item.noteType && item.noteType.id === 'oos' ? '#bfbfbf' : '#262626'}!important`}") {{item.testersquantity || 0}}
                    p(v-if="item.adjusterByClientType" class="edit-params-product" :style="{marginTop: '12px', color: item.textColor}") {{ item.adjustedTestersQuantity || 0}}
                  td.retailer-order-table__cell(:style="{color: `${item.noteType && item.noteType.id === 'oos' ? '#bfbfbf' : '#262626'}!important`}") {{priceFormat(item.recordedMsrpUsd)}}
                  td.retailer-order-table__cell(:style="{color: `${item.noteType && item.noteType.id === 'oos' ? '#bfbfbf' : '#262626'}!important`}") {{priceFormat(item.calculatedWholesalePriceUsd)}}
                  td.retailer-order-table__cell(:class="{'alignTextCenter' : alignCenter}" :style="{color: `${item.adjustedQuantity ? item.textColor : ''} !important`}") {{priceFormat(item.calculatedWholesaleCostUsd)}}
                tr
                  td.retailer-order-table__cell.table-border-b-none
                  td.retailer-order-table__cell.table-border-b-none
                  td.retailer-order-table__cell.table-border-b-none
                  td.retailer-order-table__cell.table-border-b-none
                  td.retailer-order-table__cell.table-border-b-none
                  td.retailer-order-table__cell.table-border-b-none.subtotal {{$t('Retailers.RetailerOrder.itemSubtotal')}}
                  td.retailer-order-table__cell.table-border-b-none(:class="{'alignTextCenter' : alignCenter}") {{priceFormat(productTotal)}}

          .additional-charge-tips-text(v-if="retailerTermsName")
            a-icon(type="exclamation-circle" theme="filled" :style="{color: '#FEC206', marginRight: '10px'}")
            | {{`${retailerTermsName} has added Retailer Terms`}}
          RetailerTerms(:order="order" :buttonColor="buttonColor" :retailerTermsTotal="retailerTermsTotal" :retailerLists="retailerLists" @getRetailerTermsTotal="getRetailerTermsTotal" @openAddTermsModal="openAddTermsModal")
          .additional-charge-tips-text(v-if="(isAdmin || isRetailer) && showAdditionalCharge && (additionalCharge && additionalCharge.length !== 0)")
            a-icon(type="exclamation-circle" theme="filled" :style="{color: '#7ACE93', marginRight: '10px'}")
            | {{`${additionalText} has added additional charges`}}
          AdditionalCharges(v-if="showAdditionalCharge" :order="order")
          .total-amount-number
            p
              span TOTAL
              span {{ sumTotal }}
            p(v-if="(isBrand || isAdmin) && $route.name !== 'adminRetailerOrder'")
              span.download-estimated-payment(@click="downloadEstimated") ESTIMATED PAYMENT
        .column.is-12(v-if="loadingActivity")
          a-spin
        .column.is-12(v-else)
          OrderActivity(:brand="brand" :retailer="retailer" :order="order",v-if="brand.members && retailer.members" :buttonColor="buttonColor" :comment="comment" @fetchSearchMessage="fetchSearchMessage")

    .column
      .columns.is-multiline
        .column.is-12(v-if="supplyAirTrackingInfo || supplyOceanTrackingInfo || deliveryAirTrackingInfo || deliveryOceanTrackingInfo")
          .retailer-order-information
            .retailer-order-information__container(v-if="(role=='admin' || role=='retailer') && (deliveryAirTrackingInfo || deliveryOceanTrackingInfo)")
              .retailer-order-information__block
                .shipping-first-block
                  .hipping-first-block__heading
                    .retailer-order-information__title(v-if="role=='admin'") {{$t('Components.Orders.OrderDetails.deliveryShipmentInfo')}}
                    .retailer-order-information__title(v-else) {{$t('Components.Orders.OrderDetails.shipmentInfo')}}
                    a.edit-shipment-info(@click="editTrackingInfo(orderStatus.deliverShipped)" v-if="role=='admin'") {{$t('Components.Orders.OrderDetails.edit')}}
                  div(v-if="deliveryAirTrackingInfo")
                    .retailer-order-information__header {{$t('Components.Orders.OrderDetails.shippingCarrier')}}
                    .address.shipment-provider
                      p {{deliveryAirTrackingInfo.provider.name}}
                    .retailer-order-information__header {{$t('Components.Orders.OrderDetails.trackingNumber')}}
                    .retailer-order-information__link {{deliveryAirTrackingInfo.trackingId}}
                  div(v-if="deliveryOceanTrackingInfo")
                    .retailer-order-information__header {{$t('Components.Orders.OrderDetails.forwarderName')}}
                    .address.shipment-provider
                      p {{deliveryOceanTrackingInfo.forwarderName}}
                    .retailer-order-information__header {{$t('Components.Orders.OrderDetails.vesselCompany')}}
                    .address.shipment-provider
                      p {{deliveryOceanTrackingInfo.provider.name}}
                    .retailer-order-information__header {{$t('Components.Orders.OrderDetails.houseBlNumner')}}
                    .retailer-order-information__link {{deliveryOceanTrackingInfo.houseBlNumber}}
            .retailer-order-information__container(v-if="(role=='admin' || role=='brand') && (supplyAirTrackingInfo || supplyOceanTrackingInfo)")
              .retailer-order-information__block
                .shipping-first-block
                  .hipping-first-block__heading
                    .retailer-order-information__title(v-if="role=='admin'") {{$t('Components.Orders.OrderDetails.supplyShipmentInfo')}}
                    .retailer-order-information__title(v-else) {{$t('Components.Orders.OrderDetails.shipmentInfo')}}
                    a.edit-shipment-info(@click="editTrackingInfo(orderStatus.supplyShipped)" v-if="role=='brand' || role=='admin'") {{$t('Components.Orders.OrderDetails.edit')}}
                  div(v-if="supplyAirTrackingInfo")
                    .retailer-order-information__header {{$t('Components.Orders.OrderDetails.shippingCarrier')}}
                    .address.shipment-provider
                      p {{supplyAirTrackingInfo.provider.name}}
                    .retailer-order-information__header {{$t('Components.Orders.OrderDetails.trackingNumber')}}
                    .retailer-order-information__link {{supplyAirTrackingInfo.trackingId}}
                  div(v-if="supplyOceanTrackingInfo")
                    .retailer-order-information__header {{$t('Components.Orders.OrderDetails.forwarderName')}}
                    .address.shipment-provider
                      p {{supplyOceanTrackingInfo.forwarderName}}
                    .retailer-order-information__header {{$t('Components.Orders.OrderDetails.vesselCompany')}}
                    .address.shipment-provider
                      p {{supplyOceanTrackingInfo.provider.name}}
                    .retailer-order-information__header {{$t('Components.Orders.OrderDetails.houseBlNumner')}}
                    .retailer-order-information__link {{supplyOceanTrackingInfo.houseBlNumber}}
        .column.is-12
          .retailer-order-information
            .retailer-order-information__container(v-if="isRetailer")
              .retailer-order-information__block
                .shipping-first-block
                  .hipping-first-block__heading
                    .retailer-order-information__title {{$t('Retailers.RetailerOrder.shipping')}}
                    .img-block(v-if="!brand.logoAsset.url")
                      img(src="@/assets/img/default-logo-image.png")
                    .img-block(v-if="brand.logoAsset.url")
                      img(:src="brand.logoAsset.url")
                  .retailer-order-information__header {{$t('Retailers.RetailerOrder.brand')}}
                  .retailer-order-information__link {{brand.name}}
            .retailer-order-information__container(v-if="isBrand")
              .retailer-order-information__block
                .shipping-first-block
                  .hipping-first-block__heading
                    .retailer-order-information__title {{$t('Retailers.RetailerOrder.shipping')}}
                    .img-block(v-if="!retailer.logoAsset.url")
                      img(src="@/assets/img/default-logo-image.png")
                    .img-block(v-if="retailer.logoAsset.url")
                      img(:src="retailer.logoAsset.url")
                  .retailer-order-information__header Buyer
                  .retailer-order-information__link {{retailer.name}}
            .retailer-order-information__container
              .retailer-order-information__header
                .order-information-subheading
                  span {{$t('Retailers.RetailerOrder.shippingAddress')}}
                  a-icon.edit-shipment-info(v-if="showEditContactAndAddress" type="edit" @click="showEditAddressModal=true")
                .adresss(v-if="order.shipToAddress")
                  p {{order.shipToAddress.addressee}}
                  p {{order.shipToAddress.streetAddressLine_1}}
                  p {{order.shipToAddress.streetAddressLine_2}}
                  p(v-if="order.shipToAddress.city") {{order.shipToAddress.city}}, {{order.shipToAddress.region}} {{order.shipToAddress.postalCode}}
                  p(v-if="order.shipToAddress.country") {{countryNameById(order.shipToAddress.country.id)}}
            .retailer-order-information__container
              .retailer-order-information__header
                .order-information-subheading
                  span {{$t('Retailers.RetailerOrder.contactInformation')}}
                  a-icon.edit-shipment-info(v-if="showEditContactAndAddress" type="edit" @click="showContactInfoModal()")
                .adresss(v-if="order.contact")
                  p {{order.contact.name}}
                  p {{order.contact.phone}}
            .retailer-order-information__container
              .retailer-order-information__header
                .order-information-subheading
                  span {{$t('Retailers.RetailerOrder.shipDate')}}
                  a-icon.edit-shipment-info(v-if="!isRetailer && order.status && order.status.id == orderStatus.pending && !order.declined && !(isRetailer && this.order.version === '2')" type="edit" @click="openEditShipOrCancelModal('ship')")
                .adresss
                  p {{ order.shipDate }}
            .retailer-order-information__container
              .retailer-order-information__header
                .order-information-subheading
                  span Cancel Date
                  a-icon.edit-shipment-info(v-if="!isRetailer && order.status && order.status.id == orderStatus.pending && !order.declined && !(isRetailer && this.order.version === '2')" type="edit" @click="openEditShipOrCancelModal('cancel')")
                .adresss
                  p {{ order.cancelDate }}
            .retailer-order-information__container
              .retailer-order-information__header {{$t('Retailers.RetailerOrder.estimatedPaymentDate')}}
                .adresss
                  p {{ order.calculatedPaidToBrandDate }}

        .column.is-12
          .retailer-order-information.retailer-order-information__terms
            .retailer-order-information__container
              .retailer-order-information__title {{$t('Retailers.RetailerOrder.terms')}}
            .retailer-order-information__container
              .retailer-order-information__header {{$t('Retailers.RetailerOrder.paymentTerms')}}
                .adresss(v-if="order.recordedRetailerPaymentTerm")
                  p {{ order.recordedRetailerPaymentTerm.name }}
            .retailer-order-information__container
              .retailer-order-information__header {{$t('Retailers.RetailerOrder.compliance')}}
                .adresss
                  div
                    p(v-if="retailer.terms.productTicketingRequired") {{$t('Retailers.RetailerOrder.productTicketing')}}
                    p(v-if="retailer.terms.ediRequired") {{$t('Retailers.RetailerOrder.retailerEDI')}}
                    p(v-if="retailer.terms.routingGuideRequired") {{$t('Retailers.RetailerOrder.vendorGuide')}}

  EditAddressModal(
    :show="showEditAddressModal"
    @hideAddressModal="showEditAddressModal=false"
    @updateAddress="updateShippingAddress"
    :address="order.shipToAddress"
    v-if="showEditAddressModal"
    )
  EditContactInformation(
    :show="editContactInfo"
    :contact="contactInfo"
    @hideContactInfo="editContactInfo = false"
    @contactInfoChange="updateContactInfo"
    v-if="editContactInfo"
  )
  EditShippingWindow(
    ref="editShipOrCancelDate"
    @updateShippingWindow="updateShippingWindow"
  )
  editProductModal(
    ref="showEditModal"
    :purchaseDetails="purchaseDetails"
    :buttonColor="buttonColor"
    @getProductEditList="getProductEditList"
  )
  EditAllProductInfoModal(
    ref="editAllProduct"
    :purchaseDetails="purchaseDetails"
    :buttonColor="buttonColor"
    @getProductEditList="getProductEditList"
    @updataEditProduct="updataEditProduct"
    @updateRetailerTerms="updateRetailerTerms"
  )
  addShipDetails(ref="addShipDetail")
  EstimatedPayment(ref="downloadEstimatedPayment")
  PaymentAdvice(ref="paymentAdvice" @updataEditProduct="updataEditProduct")
  addRetailerTermsModal(ref="addTermsModal" @updateRetailerTerms="updateRetailerTerms")
  TrackingInformationModal(ref="shippedModal")
</template>

<script>
import OrderActivity from "@/components/orders/OrderActivity"
import AdditionalCharges from "@/components/orders/AdditionalCharges"
import RetailerTerms from "@/components/orders/retailerTerms"
import OrderStatus from "@/components/retailers/order/OrderStatus"
import constants from "@/config.js"
import TrackingInformationModal from "@/components/orders/TrackingInformationModal"
import EditAddressModal from "@/components/orders/EditAddressModal"
import EditContactInformation from "@/components/orders/EditContactInformation"
import EditShippingWindow from "@/components/orders/EditShippingWindow"
import editProductModal from "@/components/orders/EditProductModal"
import EditAllProductInfoModal from "@/components/orders/editProductAllInfoModal"
import addShipDetails from "@/components/orders/AddShipDetailsModal"
import EstimatedPayment from "@/components/orders/EstimatedPaymentModal"
import PaymentAdvice from "@/components/orders/paymentAdviceModal"
import addRetailerTermsModal from "@/components/orders/addRetailerTermsModal"
import { priceFormat } from "@/utils/validate"
// import {messageItemizeType} from "@/utils/const";

export default {
  name: "OrderDetails",
  components: {
    OrderActivity,
    OrderStatus,
    AdditionalCharges,
    TrackingInformationModal,
    EditAddressModal,
    EditContactInformation,
    EditShippingWindow,
    editProductModal,
    RetailerTerms,
    EditAllProductInfoModal,
    addShipDetails,
    EstimatedPayment,
    PaymentAdvice,
    addRetailerTermsModal
  },
  prop: {
    orderId: { type: String, default: "" }
  },
  data() {
    return {
      priceFormat,
      editShippingWindow: false,
      contactInfo: null,
      editContactInfo: false,
      showEditAddressModal: false,
      showTrackingInfo: false,
      initialQuantity: null,
      initialTesters: null,
      alignCenter: false,
      orderStatus: constants.orderStatus,
      showAddChargeFileds: false,
      purchaseDetails: [
        {
          calculatedRetailCostUsd: "",
          calculatedWholesaleCostUsd: "",
          calculatedWholesalePriceUsd: "",
          createdAt: "",
          createdBy: "",
          product: {
            id: "",
            name: "",
            sku: ""
          },
          quantity: "",
          adjustedQuantity: "",
          quantityType: {
            id: "",
            name: ""
          },
          testersquantity: "",
          editTesterValue: "",
          adjustedTestersQuantity: 0,
          recordedMargin: "",
          recordedMsrpUsd: "",
          noteType: {
            id: ""
          },
          textColor: ""
        }
      ],
      itemIndex: -1,
      editingIndex: -1,
      isEditing: false,
      productIndex: -1,
      productEditIndex: -1,
      isSaveDisabled: true,
      trackingInfo: [],
      isEditTrackingInfo: false,
      airShipments: [],
      oceanShipments: [],
      trackingStatus: "",
      editProductList: [],
      trtailerTermsTotal: 0,
      retailerLists: [],
      retailerTermsTotal: 0,
      comment: {},
      loadingActivity: false
    }
  },
  computed: {
    showEditContactAndAddress() {
      return (
        this.order.status &&
        this.order.status.id == this.orderStatus.pending &&
        this.isRetailer &&
        !this.order.declined
      )
    },
    showEditShippingWindow() {
      return (
        this.order.status &&
        this.order.status.id == this.orderStatus.pending &&
        this.isBrand &&
        !this.order.declined
      )
    },
    supplyAirTrackingInfo() {
      return (
        this.order.airShipments &&
        this.order.airShipments.filter(
          item => item.orderStatus.id === this.orderStatus.supplyShipped
        )[0]
      )
    },
    supplyOceanTrackingInfo() {
      return (
        this.order.oceanShipments &&
        this.order.oceanShipments.filter(
          item => item.orderStatus.id === this.orderStatus.supplyShipped
        )[0]
      )
    },
    deliveryAirTrackingInfo() {
      return (
        this.order.airShipments &&
        this.order.airShipments.filter(
          item => item.orderStatus.id === this.orderStatus.deliverShipped
        )[0]
      )
    },
    deliveryOceanTrackingInfo() {
      return (
        this.order.oceanShipments &&
        this.order.oceanShipments.filter(
          item => item.orderStatus.id === this.orderStatus.deliverShipped
        )[0]
      )
    },
    showTrackingInfoButton() {
      return (
        this.role == "brand" &&
        (this.statusItemsNames.indexOf(this.orderStatus.supplyShipped) <=
          this.statusItemsNames.indexOf(this.order.status.id) ||
          this.order.status.id == this.orderStatus.paidByRetailer) &&
        !this.supplyAirTrackingInfo &&
        !this.supplyOceanTrackingInfo
      )
    },
    statusItemsNames() {
      if (!this.order) {
        return false
      }
      return this.order.lifecycle && Object.values(this.order.lifecycle).map(item => item.status.id)
    },
    isProductEditable() {
      return (
        this.order.status &&
        (this.role === "brand" || this.$route.name === "adminBrandOrder") &&
        this.order.status.id === this.orderStatus.pending &&
        !this.isEditing
      )
    },
    role() {
      return this.$store.getters["user/role"]
    },
    isRetailer() {
      return this.role === "retailer" || constants.adminRetailerRoutes.includes(this.$route.name)
    },
    isBrand() {
      return this.role === "brand" || constants.adminBrandRoutes.includes(this.$route.name)
    },
    order() {
      return this.$store.getters["order/order"]
    },
    retailer() {
      return this.$store.getters["retailer/retailer"]
    },
    brand() {
      return this.$store.getters["brand/brand"]
    },
    countries() {
      return this.$store.getters["reference/countries"]
    },
    isAdmin() {
      return this.$store.getters["user/isAdmin"]
    },
    getProductByIds() {
      return this.$store.getters["product/productsForCart"]
    },
    retailerOrBrandOrAdminInfo() {
      if (this.isBrand) {
        return this.$store.getters["brand/brand"]
      } else if (this.isRetailer) {
        return this.$store.getters["retailer/retailer"]
      } else {
        return this.$store.getters["admin/admin"]
      }
    },
    additionalText() {
      let result = []
      if (this.isBrand) {
        result =
          this.order.charges &&
          this.order.charges.filter(
            item => item.seenByBrand && item.ownedBy.id === "brand" && item.paidBy.id === "retailer"
          )
      } else if (this.isRetailer) {
        result =
          this.order.charges &&
          this.order.charges.filter(
            item =>
              item.seenByRetailer && item.ownedBy.id === "brand" && item.paidBy.id === "retailer"
          )
      } else {
        result =
          this.order.charges &&
          this.order.charges.filter(
            item =>
              item.seenByLanding && item.ownedBy.id === "brand" && item.paidBy.id === "retailer"
          )
      }
      if (
        result &&
        result.length !== 0 &&
        result[0].addedBy &&
        result[0].addedBy.id === "landing"
      ) {
        return "Landing"
      } else {
        return this.brand.name
      }
    },
    retailerTermsName() {
      let retailerTerms = ""
      const reatailerCharge =
        this.order.charges &&
        this.order.charges.filter(
          item =>
            item.ownedBy.id === "retailer" &&
            item.paidBy.id === "brand" &&
            item.chargeType.id === "custom"
        )
      if (reatailerCharge && reatailerCharge.length !== 0) {
        if (reatailerCharge[0].addedBy.id === "landing") {
          retailerTerms = "Landing"
        } else if (reatailerCharge[0].addedBy.id === "retailer") {
          retailerTerms = this.retailer.name
        }
      }
      return retailerTerms
    },
    bgColor() {
      if (this.isBrand) {
        return "rgba(74, 138, 93, 0.11)"
      } else if (this.isRetailer) {
        return "#FEF2E2"
      } else {
        return "#FFFAF9"
      }
    },
    buttonColor() {
      if (this.isBrand) {
        return "#4a8a5d"
      } else if (this.isRetailer) {
        return "#FEC206"
      } else {
        return "#FF6132"
      }
    },
    showAdditionalCharge() {
      if (this.isRetailer) {
        const list =
          this.order &&
          this.order.charges &&
          this.order.charges.filter(
            item =>
              item.seenByRetailer && item.ownedBy.id === "brand" && item.paidBy.id === "retailer"
          )
        return list && list.length !== 0
      }
      return true
    },
    // additionalCharges() {
    //   if (this.isBrand) {
    //     return this.order.charges && this.order.charges.filter(item => item.seenByBrand)
    //   } else if (this.isRetailer) {
    //     return this.order.charges && this.order.charges.filter(item => item.seenByRetailer)
    //   } else {
    //     return this.order.charges
    //   }
    // },
    totalAdditionalCharge() {
      if (this.additionalCharge && this.additionalCharge.length !== 0) {
        return this.additionalCharge.reduce((total, charge) => {
          if (charge.paidBy.id == "brand") {
            return total - parseFloat(charge.calculatedCostUsd)
          } else {
            return total + parseFloat(charge.calculatedCostUsd)
          }
        }, 0)
      } else {
        return 0
      }
    },
    additionalCharge() {
      if (this.isBrand) {
        return (
          this.order.charges &&
          this.order.charges.filter(
            item => item.seenByBrand && item.ownedBy.id === "brand" && item.paidBy.id === "retailer"
          )
        )
      } else if (this.isRetailer) {
        return (
          this.order.charges &&
          this.order.charges.filter(
            item =>
              item.seenByRetailer && item.ownedBy.id === "brand" && item.paidBy.id === "retailer"
          )
        )
      } else {
        return (
          this.order.charges &&
          this.order.charges.filter(
            item =>
              item.seenByLanding && item.ownedBy.id === "brand" && item.paidBy.id === "retailer"
          )
        )
      }
    },
    productTotal() {
      let list = this.editProductList
      if (list && list.length === 0) {
        list = this.orderDetails()
      }
      if (!list) {
        list = []
      }
      return list.reduce((total, charge) => {
        // if (charge.paidBy.id == "brand") {
        //   return total - parseFloat(charge.calculatedCostUsd)
        // } else {
        return total + Number(charge.calculatedWholesaleCostUsd)
        // }
      }, 0)
    },
    sumTotal() {
      let retailerValue, chargeValue
      if (this.retailerTermsTotal < 0) {
        retailerValue = this.retailerTermsTotal * -1
      } else {
        retailerValue = this.retailerTermsTotal
      }
      if (this.additionalCharge && this.additionalCharge.length !== 0) {
        chargeValue = this.additionalCharge[0].percentage
          ? this.additionalCharge[0].calculatedCostUsd
          : this.additionalCharge[0].recordedValue
      } else {
        chargeValue = 0
      }

      const first = eval(this.productTotal - retailerValue + Number(chargeValue))
      return this.priceFormat(first)
    },
    differentStatusHasText() {
      let textInfo = {
        title: "",
        content: "",
        buttonList: []
      }
      if (this.order && this.order.status) {
        // when this
        if (this.isBrand || this.$route.name === "adminBrandOrder") {
          // 1: retailer create new order, need brand accept
          if (
            this.order.status.id === this.orderStatus.pending &&
            !this.order.brandAccepted &&
            this.order.retailerAccepted &&
            this.order.version === "2"
          ) {
            textInfo.title = "You have a new order!"
            textInfo.content = "Please confirm, request changes, or cancel your order."
            textInfo.buttonList = [
              { name: "ACCEPT ORDER", isAccept: true, status: this.orderStatus.confirmed },
              { name: "EDIT ORDER", isEdit: true },
              { name: "CANCEL ORDER", status: "canceled" }
            ]
          }
          // 2. retailer edit, need brand edit/accept
          if (
            this.order.status.id === this.orderStatus.pending &&
            !this.order.brandAccepted &&
            this.order.retailerAccepted &&
            this.order.version !== "2"
          ) {
            textInfo.title = "Your order has been edited."
            textInfo.content = `Please review and respond. Ask questions or request changes using order Comments.`
            textInfo.buttonList = [
              { name: "ACCEPT ORDER", isAccept: true, status: this.orderStatus.confirmed },
              { name: "EDIT ORDER", isEdit: true },
              // {
              //   name: "DECLINE CHANGES",
              //   status: this.order.status.id,
              //   isDecline: true
              // },
              { name: "CANCEL ORDER", status: "canceled" }
            ]
          }
          // 3. brand edit, need retailer edit/accept
          if (
            this.order.status.id === this.orderStatus.pending &&
            this.order.brandAccepted &&
            !this.order.retailerAccepted &&
            this.order.version !== "2"
          ) {
            textInfo.title = "Your edits have been sent."
            textInfo.content = `Your edits have been sent to ${this.retailer.name}. ${this.retailer.name} will review and respond`
          }
          // 4 brand/retailer=> decline
          if (this.order.declined && !this.order.brandAccepted) {
            textInfo.title = "Requests declined. "
            textInfo.content = `${this.retailer.name} has declined your edits.`
          }
          if (this.order.declined && this.order.brandAccepted) {
            textInfo.title = "You have declined change requests. "
          }
          if (this.order.status.id === this.orderStatus.canceled) {
            textInfo.title = "Order canceled. "
            textInfo.content = `${this.order.brandAccepted ? "you" : this.retailer.name
              } has canceled this order. `
          }
          if (this.order.status.id === this.orderStatus.confirmed) {
            textInfo.title = "Next step: Ship your order."
            textInfo.content = `Congratulations, your order is confirmed. A packing list is now available for download. Please click “ADD SHIP DETAILS” to submit shipping details to ${this.retailer.name}.`
            textInfo.buttonList = [
              { name: "ADD SHIP DETAILS", isAddShip: true, status: this.orderStatus.delivered }
            ]
          }
          // shipped and invoiced
          if (
            this.order.status.id === this.orderStatus.supplyShipped ||
            this.order.status.id === this.orderStatus.warehouse ||
            this.order.status.id === this.orderStatus.deliverShipped ||
            this.order.status.id === this.orderStatus.delivered
          ) {
            textInfo.title = "Your order has been shipped."
            textInfo.content = `An invoice is now available for download.`
          }
          if (
            this.order.status.id === this.orderStatus.paidToBrand ||
            this.order.status.id === this.orderStatus.paidByRetailer
          ) {
            textInfo.title = "Your order has been paid."
            textInfo.content = `Payment Advice is now available for download. This order is now complete.`
          }
        }
        // When the account is retailer
        if (this.isRetailer || this.$route.name === "adminRetailerOrder") {
          // 1.retailer create new order
          if (
            this.order.status.id === this.orderStatus.pending &&
            !this.order.brandAccepted &&
            this.order.retailerAccepted &&
            this.order.version === "2"
          ) {
            textInfo.title = "Thank you! "
            textInfo.content = `Your purchase order has been submitted to ${this.brand.name}. ${this.brand.name} will review and respond. `
            textInfo.buttonList = [{ name: "CANCEL ORDER", status: "canceled" }]
          }
          // 2.brand edit , need retailer edit/accept/cancel...
          if (
            this.order.status.id === this.orderStatus.pending &&
            this.order.brandAccepted &&
            !this.order.retailerAccepted &&
            !this.order.declined &&
            this.order.version !== "2"
          ) {
            textInfo.title = "Your order has been edited. "
            textInfo.content = `Please review and respond. Ask questions or request changes using order Comments. `
            textInfo.buttonList = [
              { name: "ACCEPT ORDER", isAccept: true, status: this.orderStatus.confirmed },
              // { name: "EDIT ORDER", isEdit: true },
              // {
              //   name: "DECLINE CHANGES",
              //   status: this.order.status.id,
              //   isDecline: true
              // },
              { name: "CANCEL ORDER", status: "canceled" }
            ]
          }
          // 2-1: if retailer edit, retailer view page
          if (
            this.order.status.id === this.orderStatus.pending &&
            !this.order.brandAccepted &&
            this.order.retailerAccepted &&
            !this.order.declined &&
            this.order.version !== "2"
          ) {
            textInfo.title = "Your edits have been sent. "
            textInfo.content = `Your change requests have been sent to ${this.brand.name}.  ${this.brand.name} will review and respond.`
          }
          if (this.order.declined && this.order.retailerAccepted) {
            textInfo.title = "You have declined change requests. "
          }
          if (this.order.declined && !this.order.retailerAccepted) {
            textInfo.title = "Order changes declined. "
            textInfo.content = `${this.brand.name} has declined the order change requests. `
          }
          if (this.order.status.id === this.orderStatus.confirmed) {
            textInfo.title = "Your order is confirmed."
            textInfo.content = `${this.brand.name} will let you know when the order has been shipped. `
          }
          if (
            this.order.status.id === this.orderStatus.supplyShipped ||
            this.order.status.id === this.orderStatus.warehouse ||
            this.order.status.id === this.orderStatus.deliverShipped ||
            this.order.status.id === this.orderStatus.delivered
          ) {
            textInfo.title = "Your order has shipped."
            textInfo.content = `An invoice is now available for download.`
          }
          if (
            this.order.status.id === this.orderStatus.paidByRetailer ||
            this.order.status.id === this.orderStatus.paidToBrand
          ) {
            textInfo.title = "Payment complete."
            textInfo.content = `Your payment for this order has been received.`
          }
          if (this.order.status.id === this.orderStatus.canceled) {
            textInfo.title = "Order canceled. "
            textInfo.content = `${this.order.retailerAccepted ? "you" : this.brand.name
              } have canceled this order. `
          }
        }
        // When the account is admin
        if (
          this.isAdmin &&
          this.$route.name !== "adminRetailerOrder" &&
          this.$route.name !== "adminBrandOrder"
        ) {
          // 1.retailer create order
          if (
            this.order.status.id === this.orderStatus.pending &&
            this.order.retailerAccepted &&
            !this.order.brandAccepted &&
            this.order.version === "2"
          ) {
            textInfo.title = `${this.brand.name} has received an order from ${this.retailer.name}.`
            textInfo.content = `${this.brand.name} is reviewing the order. `
            textInfo.buttonList = [
              { name: "EDIT ORDER", isEdit: true },
              { name: "DELETE ORDER", isDelete: true, status: this.orderStatus.deleted }
            ]
          }
          // 2.brand edit order
          if (
            this.order.status.id === this.orderStatus.pending &&
            this.order.brandAccepted &&
            !this.order.retailerAccepted &&
            !this.order.declined &&
            this.order.version !== "2"
          ) {
            textInfo.title = "Changes requested to order. "
            textInfo.content = `${this.brand.name} has submitted a change request and is waiting for a response. `
            textInfo.buttonList = [
              { name: "EDIT ORDER", isEdit: true },
              { name: "DELETE ORDER", isDelete: true, status: this.orderStatus.deleted }
            ]
          }
          // 3. retailer edit order
          if (
            this.order.status.id === this.orderStatus.pending &&
            !this.order.brandAccepted &&
            this.order.retailerAccepted &&
            !this.order.declined &&
            this.order.version !== "2"
          ) {
            textInfo.title = "Changes requested to order. "
            textInfo.content = `${this.retailer.name} has submitted a change request and is waiting for a response. `
            textInfo.buttonList = [
              { name: "EDIT ORDER", isEdit: true },
              { name: "DELETE ORDER", isDelete: true, status: this.orderStatus.deleted }
            ]
          }
          if (this.order.declined && !this.order.retailerAccepted) {
            textInfo.title = `${this.brand.name} declined change request. `
            textInfo.content = `${this.retailer.name} requested changes have been declined. `
            textInfo.buttonList = [
              { name: "DELETE ORDER", isDelete: true, status: this.orderStatus.deleted }
            ]
          }
          if (this.order.declined && this.order.retailerAccepted) {
            textInfo.title = `${this.retailer.name} declined change request. `
            textInfo.content = `${this.brand.name} requested changes have been declined. Waiting for ${this.brand.name}'s response.   `
            textInfo.buttonList = [
              { name: "DELETE ORDER", isDelete: true, status: this.orderStatus.deleted }
            ]
          }
          if (this.order.status.id === this.orderStatus.canceled && this.order.retailerAccepted) {
            textInfo.title = `Order canceled. `
            textInfo.content = `${this.retailer.name} has cancelled this order.`
          }
          if (this.order.status.id === this.orderStatus.canceled && !this.order.retailerAccepted) {
            textInfo.title = `Order canceled. `
            textInfo.content = `${this.brand.name} has cancelled this order.`
          }
          if (this.order.status.id === this.orderStatus.confirmed) {
            textInfo.title = "Order has been confirmed. "
            textInfo.content = `Both ${this.retailer.name} and ${this.brand.name} have accepted the terms of this order. If fulfilled through Landing, mark as shipped.`
            textInfo.buttonList = [
              { name: "MARK AS SHIPPED", isShipped: true, status: this.orderStatus.delivered }
            ]
          }
          if (
            this.order.status.id === this.orderStatus.supplyShipped ||
            this.order.status.id === this.orderStatus.warehouse ||
            this.order.status.id === this.orderStatus.deliverShipped ||
            this.order.status.id === this.orderStatus.delivered
          ) {
            textInfo.title = "Next step: Mark as paid."
            textInfo.content = `Order has been shipped and invoice is now available for download. Mark as paid once payment is received from ${this.retailer.name}. `
            textInfo.buttonList = [
              { name: "MARK AS PAID ", isShipped: true, status: this.orderStatus.paidByRetailer }
            ]
          }
          if (this.order.status.id === this.orderStatus.paidByRetailer) {
            textInfo.title = `Next step: Mark as paid to Brand.`
            textInfo.content = `Payment for this order has been received. Pay Brand and update order status. `
            textInfo.buttonList = [
              { name: "PAYMENT ADVICE ", isShowAdvice: true },
              { name: "PAID TO BRAND", isShipped: true, status: this.orderStatus.paidToBrand }
            ]
          }
          if (this.order.status.id === this.orderStatus.paidToBrand) {
            textInfo.title = `Paid to ${this.brand.name}.`
            textInfo.content = `This order is now complete.`
          }
        }
      }

      return textInfo
    }
  },
  async mounted() {
    this.loadingActivity = true
    await this.$store.dispatch("order/getOrder", this.$route.params.id)
    this.purchaseDetails = this.orderDetails()
    this.updateRetailerTerms()
    this.$store.dispatch("retailer/fetchRetailer", this.order.retailer.id)
    await this.$store.dispatch("brand/getBrand", this.order.brand.id)
    this.$store.dispatch("reference/setReference")
    const params = {
      itemize: "send",
      _limit: 50,
      _index: 1,
      "order-id": this.$route.params.id
      // "client-id": this.brand.id,
      // "message-type-id": "Order",
      // category: "Orders"
    }
    await this.fetchSearchMessage(params)
    this.loadingActivity = false
  },
  methods: {
    async fetchSearchMessage(params) {
      this.comment = await this.$store.dispatch("adminMessage/fetchSearchMessage", { params })
    },
    async updateRetailerTerms(charges, isSubmit) {
      let chargeList = charges ? charges : this.order.charges
      if (chargeList && chargeList.length === 0) {
        return
      }
      if (this.isRetailer) {
        chargeList = chargeList.filter(
          item =>
            item.seenByRetailer && item.ownedBy.id === "retailer" && item.paidBy.id === "brand"
        )
      } else if (this.isBrand) {
        chargeList = chargeList.filter(
          item => item.seenByBrand && item.ownedBy.id === "retailer" && item.paidBy.id === "brand"
        )
      } else if (this.isAdmin) {
        chargeList = chargeList.filter(
          item => item.seenByLanding && item.ownedBy.id === "retailer" && item.paidBy.id === "brand"
        )
      }
      chargeList = chargeList.map(item => {
        if (item.percentage) {
          item.calculatedCostUsd = (Number(item.recordedValue) / 100) * this.productTotal
        }
        return item
      })
      const RList = chargeList
      this.retailerLists = RList.map(item => {
        if (
          item.ownedBy.id === "retailer" &&
          item.paidBy.id === "brand" &&
          item.chargeType.id === "custom"
        ) {
          item.textColor = "#FEC206 !important"
        } else {
          item.textColor = "#333333 !important"
        }
        return item
      })
      this.retailerTermsTotal = chargeList.reduce((total, charge) => {
        if (charge.paidBy.id == "brand") {
          return total - parseFloat(charge.calculatedCostUsd)
        } else {
          return total + parseFloat(charge.calculatedCostUsd)
        }
      }, 0)
      this.purchaseDetails = this.orderDetails()
      if (!isSubmit) {
        return
      }
      const acceptInfo = this.formatUsersAccepted()
      let order = {
        ...acceptInfo,
        id: this.order.id,
        status: {
          id: this.order.status.id
        },
        version: Number(this.order.version) + 1,
        charges
      }
      try {
        await this.fetchUploadOrder(order)
        this.purchaseDetails = this.orderDetails()
        this.updateRetailerTerms()
      } catch (error) {
        console.error(error)
      }
    },
    async getProductEditList(sendList) {
      const acceptInfo = this.formatUsersAccepted()
      const items = sendList
      let order = {
        ...acceptInfo,
        id: this.order.id,
        status: {
          id: this.order.status.id
        },
        version: Number(this.order.version) + 1,
        items
      }
      try {
        await this.fetchUploadOrder(order)
        this.purchaseDetails = this.orderDetails()
        this.updateRetailerTerms()
      } catch (error) {
        console.error(error)
      }
    },
    getRetailerTermsTotal(trtailerTermsTotal) {
      this.trtailerTermsTotal = trtailerTermsTotal
    },
    editTrackingInfo(status) {
      this.trackingStatus = status
      this.isEditTrackingInfo = true
      this.airShipments = this.order.airShipments && [
        ...this.order.airShipments.map(item => {
          return {
            orderStatus: { ...item.orderStatus },
            provider: { ...item.provider },
            trackingId: item.trackingId
          }
        })
      ]
      this.oceanShipments = this.order.oceanShipments && [
        ...this.order.oceanShipments.map(item => {
          return {
            orderStatus: { ...item.orderStatus },
            provider: { ...item.provider },
            ...item
          }
        })
      ]
      // this.showTrackingInfo = true
      this.openShippedModal()
    },
    showTrackingInfoModal() {
      if (this.role == "brand") {
        this.trackingStatus = this.orderStatus.supplyShipped
      } else if (
        this.role == "admin" &&
        (this.supplyAirTrackingInfo || this.supplyOceanTrackingInfo)
      ) {
        this.trackingStatus = this.orderStatus.deliverShipped
      } else if (
        this.role == "admin" &&
        (this.deliveryAirTrackingInfo || this.deliveryOceanTrackingInfo)
      ) {
        this.trackingStatus = this.orderStatus.deliverShipped
      } else {
        this.trackingStatus = ""
      }
      this.airShipments = this.order.airShipments && [
        ...this.order.airShipments.map(item => {
          return {
            orderStatus: { ...item.orderStatus },
            provider: { ...item.provider },
            trackingId: item.trackingId
          }
        })
      ]
      this.oceanShipments = this.order.oceanShipments && [
        ...this.order.oceanShipments.map(item => {
          return {
            orderStatus: { ...item.orderStatus },
            provider: { ...item.provider },
            ...item
          }
        })
      ]
      this.isEditTrackingInfo = false
      // this.showTrackingInfo = true
      this.openShippedModal()
    },
    orderDetails() {
      let purchase =
        this.order &&
        this.order.items &&
        this.order.items.filter(item => item.quantityType.id === "purchase")
      let testers =
        this.order &&
        this.order.items &&
        this.order.items.filter(item => item.quantityType.id === "testers")
      if (purchase && purchase.length !== 0 && testers && testers.length.length !== 0) {
        purchase = purchase.map(purchaseElement => {
          testers.forEach(testerElement => {
            if (purchaseElement.product.id == testerElement.product.id) {
              purchaseElement.testersquantity = testerElement.quantity || 0
              purchaseElement.adjustedTestersQuantity =
                testerElement.adjustedQuantity || testerElement.adjustedQuantity === 0
                  ? testerElement.adjustedQuantity
                  : testerElement.quantity
              purchaseElement.testerStock = testerElement.stock || 0
            }
          })
          if (
            purchaseElement.adjusterByClientType &&
            Object.keys(purchaseElement.adjusterByClientType).length !== 0
          ) {
            purchaseElement.textColor =
              purchaseElement.adjusterByClientType.id === "retailer" ? "#FEC206" : "#7ACE93"
          } else {
            purchaseElement.textColor = "#7ACE93"
          }
          return purchaseElement
        })
      }
      // sort by sku
      purchase.sort((a, b) => {
        if (a.product.sku < b.product.sku) return -1;
        if (a.product.sku > b.product.sku) return 1;
        return 0;
      });

      return purchase
    },

    countryNameById(countryId) {
      const country = this.countries.find(country => country.id === countryId)
      return country && country.name
    },
    async acceptOrder(state) {
      let order = {
        id: this.order.id,
        version: Number(this.order.version) + 1,
        isBrandAccepted: true,
        isRetailerAccepted: true,
        isLandingAccepted: true,
        status: {
          id: state
        }
      }
      try {
        await this.fetchUploadOrder(order)
      } catch (error) {
        console.error(error)
      }
    },
    openAcceptMessage(state) {
      const _this = this
      let classText
      if (this.isAdmin) classText = "delete-dialog-tips-admin"
      if (this.isBrand) classText = "delete-dialog-tips-brand"
      if (this.isRetailer) classText = "delete-dialog-tips-retailer"
      this.$confirm({
        title: "Order Confirmation",
        content: "Do you accept the changes and confirm the order?",
        cancelText: "NO",
        okText: "YES",
        class: classText,
        iconType: "exclamation-circle",
        async onOk() {
          await _this.acceptOrder(state)
        }
      })
    },
    async fetchUploadOrder(order) {
      let params = {}
      if (this.$route.name == "adminBrandOrder") {
        params = { "_jwt-c_id": this.order.brand.id, "_jwt-c_type": "brand" }
      }
      if (this.$route.name == "adminRetailerOrder") {
        params = { "_jwt-c_id": this.order.retailer.id, "_jwt-c_type": "retailer" }
      }
      await this.$store.dispatch("order/updateStatus", { order, params })
      await this.$store.dispatch("order/getOrder", this.$route.params.id)
    },
    formatUsersAccepted() {
      let obj = {
        isBrandAccepted: false,
        isRetailerAccepted: false,
        isLandingAccepted: false
      }
      if (this.isAdmin) {
        obj.isBrandAccepted = true
        obj.isRetailerAccepted = false
        obj.isLandingAccepted = false
      }
      if (this.isBrand) {
        obj.isBrandAccepted = true
        obj.isRetailerAccepted = false
        obj.isLandingAccepted = false
      }
      if (this.isRetailer) {
        obj.isBrandAccepted = false
        obj.isRetailerAccepted = true
        obj.isLandingAccepted = false
      }
      return obj
    },
    transitionObject(nextStatus, isDeclined) {
      const acceptInfo = this.formatUsersAccepted()
      let result = {
        ...acceptInfo,
        id: this.order.id,
        status: {
          id: nextStatus
        },
        version: Number(this.order.version) + 1
      }
      // if (nextStatus == this.orderStatus.pending) {
      //   result.isBrandAccepted = false
      //   result.isRetailerAccepted = false
      // }
      if (isDeclined) {
        result.declined = isDeclined
      }
      if (nextStatus === this.orderStatus.paidToBrand) {
        result.paymentAdviceStatus = true
      }
      return result
    },
    async statusTransition(Status, otherValue, isDeclined) {
      let nextStatus = otherValue ? otherValue : Status
      let order = this.transitionObject(nextStatus, isDeclined)
      if (nextStatus === "deleted" || nextStatus === "canceled" || isDeclined) {
        this.openDeleteDialog(order, nextStatus)
        return
      }
      if (
        (nextStatus === this.orderStatus.supplyShipped &&
          !this.supplyAirTrackingInfo &&
          !this.supplyOceanTrackingInfo) ||
        (nextStatus === this.orderStatus.deliverShipped &&
          !this.deliveryAirTrackingInfo &&
          !this.deliveryOceanTrackingInfo) ||
        (nextStatus === this.orderStatus.delivered &&
          !this.deliveryAirTrackingInfo &&
          !this.deliveryOceanTrackingInfo)
      ) {
        this.$refs["addShipDetail"].openDialog(this.order, nextStatus, this.buttonColor)
        // this.showTrackingInfoModal()
        return
      }
      try {
        await this.fetchUploadOrder(order)
      } catch (error) {
        console.error(error)
      }
    },
    async reStatusTransition(Status) {
      const otherCharge = this.order.charges.filter(
        item =>
          item.ownedBy.id === "brand" &&
          item.paidBy.id === "landing" &&
          item.chargeType.id === "custom"
      )
      let charge = this.order.charges.filter(
        item =>
          !(
            item.ownedBy.id === "brand" &&
            item.paidBy.id === "landing" &&
            item.chargeType.id === "custom"
          )
      )
      if (otherCharge && otherCharge.length !== 0) {
        otherCharge[0].addedBy = {
          id: this.isAdmin ? "landing" : "brand"
        }
        otherCharge[0].calculatedCostUsd = 0
        otherCharge[0].recordedValue = 0
        charge.push(otherCharge[0])
      }
      const order = {
        id: this.order.id,
        status: {
          id: Status
        },
        charges: charge,
        paymentAdviceStatus: false
      }
      try {
        await this.fetchUploadOrder(order)
      } catch (error) {
        console.error(error)
      }
    },
    openDeleteDialog(order, status) {
      let text = ""
      let text1 = ""
      if (status === "deleted") {
        text = "DELETE"
        text1 = "delete"
      } else if (status === "canceled") {
        text = "CANCEL"
        text1 = "cancel"
      } else {
        text = "DECLINE"
        text1 = "decline"
      }
      let classText
      if (this.isAdmin) classText = "delete-dialog-tips-admin"
      if (this.isBrand) classText = "delete-dialog-tips-brand"
      if (this.isRetailer) classText = "delete-dialog-tips-retailer"
      const _this = this
      this.$confirm({
        title: `Are you sure you wish to ${text1} this order?`,
        content: "This action cannot be undone.",
        cancelText: `NO, DON’T ${text}`,
        okText: `YES, ${text}`,
        class: classText,
        iconType: "exclamation-circle",
        async onOk() {
          await _this.fetchUploadOrder(order)
        }
      })
    },
    decreaseQuantity(item) {
      if (item.quantity > 1) {
        item.quantity = Number(item.quantity) - 1
        this.isSaveDisabled = false
      }
    },
    increaseQuantity(item) {
      if (item.quantity < this.initialQuantity) {
        item.quantity = Number(item.quantity) + 1
      }
      if (item.quantity == this.initialQuantity && item.testersquantity == this.initialTesters) {
        this.isSaveDisabled = true
      }
    },
    updateQuantitiyObject() {
      return {
        id: this.order.id,
        items: this.order.items,
        version: Number(this.order.version) + 1,
        isRetailerAccepted: false
      }
    },
    async updataEditProduct(order) {
      await this.fetchUploadOrder(order)
    },
    updateShippingAddress(addressModal) {
      let order = {
        id: this.order.id,
        shipToAddress: addressModal,
        isRetailerAccepted: this.order.retailerAccepted,
        isBrandAccepted: false,
        version: Number(this.order.version) + 1
      }
      this.updateShippingDetails(order)
    },
    showContactInfoModal() {
      this.editContactInfo = true
      let names = this.order.contact && this.order.contact.name.split(" ")
      this.contactInfo = {
        firstName: names[0],
        lastName: names[1],
        phone: this.order.contact && this.order.contact.phone
      }
    },
    updateContactInfo(updatedContact) {
      let order = {
        id: this.order.id,
        contact: updatedContact,
        isRetailerAccepted: this.order.retailerAccepted,
        isBrandAccepted: false,
        version: Number(this.order.version) + 1
      }
      this.updateShippingDetails(order)
    },
    updateShippingWindow(shipDate, cancelDate) {
      let order = {
        id: this.order.id,
        shipDate: shipDate,
        cancelDate: cancelDate,
        version: Number(this.order.version) + 1,
        isBrandAccepted: this.isBrand ? true : false,
        isRetailerAccepted: this.isBrand ? false : true
      }
      this.updateShippingDetails(order)
    },
    async updateShippingDetails(order) {
      try {
        await this.fetchUploadOrder(order)
      } catch (error) {
        console.error(error)
      }
    },
    openEditDialog() {
      this.$refs["showEditModal"].openDialog(this.order)
    },
    openAddTermsModal() {
      this.$refs["addTermsModal"].openDialog(this.order, this.buttonColor)
    },
    openEditAllProductModal(order, status, buttonColor) {
      this.$refs["editAllProduct"].openDialog(order, status, buttonColor)
    },
    openAddShipped(order, status, color) {
      this.$refs["addShipDetail"].openDialog(order, status, color)
    },
    downloadEstimated() {
      this.$refs["downloadEstimatedPayment"].openDialog(this.order, this.buttonColor, this.retailer)
    },
    openPaymentAdvice() {
      // open advice modal
      this.$refs["paymentAdvice"].openDialog(this.order)
    },
    openShippedModal() {
      this.$refs["shippedModal"].openDialog(
        this.airShipments,
        this.oceanShipments,
        this.isEditTrackingInfo,
        this.trackingStatus,
        this.buttonColor
      )
    },
    openEditShipOrCancelModal(value) {
      this.$refs["editShipOrCancelDate"].openDialog(value, this.buttonColor)
    }
  }
}
</script>

<style lang="sass">
.retailer-order
  //padding-top: 40px
  //padding-bottom: 80px
  padding: 40px 122px 80px 122px

  .retailer-order-status-banner__block
    display: flex
    //justify-content: space-between
    align-items: center
    padding-right: 20px
    .retailer-order-status-banner__left-block
      margin-bottom: 0px
    .retailer-order-status-banner__button-block
      margin-left: auto
      margin-top: 10px
      display: flex
      align-items: center
      flex-wrap: wrap-reverse
      .button
        margin-bottom: 10px
      .button.button
        margin-left: 10px

  .retailer-order-table__container
    .retailer-order-table
      padding: 20px 0px
      .reretailer-order-table__header-container
        .button[disabled]
          cursor: default
        display: flex
        justify-content: space-between
        .add-charge-button
          margin-right: 10px

      .retailer-order-table__cell
        color: #333
        font-size: 14px
        font-family: "Proxima Nova", "Roboto", sans-serif
        .additional-charge
          width: 100%
          select
            width: 100%
            height: 36px
        .amount-input-container
          display: flex
          margin-bottom: 7px
          .input
            width: 70px
            margin-right: 4px
          .additional-charge-dropdown
            .select
              height: 36px
              select
                height: 100%
        .additional-charge-buttons
          width: 129px
          display: flex
          justify-content: flex-end
          padding: 5px 0
          .button[disabled]
            cursor: default
          .save-additional-charge
            margin-right: 10px
            font-size: 14px
            height: 25px
            width: 50px
          .cancel-additional-charge
            font-size: 14px
            height: 25px
            width: 50px
            text-decoration: underline
            color: #4250C7
            border: none
        .retailer-order-quantity-input
          text-align: right !important
          width: 70px
          .retailer-order-input
            width: 74px
            height: 41px
            position: relative
            text-align: center
          .retailer-order-input-plus
            position: absolute
            top: 0px
            right: 0px
            border-left: 1px solid #E5E5E5
            border-bottom: 1px solid #E5E5E5
            height: 21px
            width: 21px
            display: flex
            align-items: center
            justify-content: center
            cursor: pointer
          .retailer-order-input-minus
            position: absolute
            top: 20px
            right: 0px
            border-left: 1px solid #E5E5E5
            height: 21px
            width: 21px
            display: flex
            align-items: center
            justify-content: center
            cursor: pointer
        .product-edit-buttons
          display: flex
          justify-content: flex-start
          padding: 25px 0 0 0
          .button[disabled]
            cursor: default
          .save-additional-charge
            margin-right: 10px
            font-size: 14px
            height: 25px
            width: 50px
          .cancel-additional-charge
            font-size: 14px
            height: 25px
            width: 50px
            text-decoration: underline
            color: #4250C7
            border: none

      .table-border-b-none
        border-bottom: none !important

      .retailer-order-table__cell.subtotal
        font-weight: 700

      .brand-order-table__cell.subtotal
        font-weight: 700

      .total
        padding-top: 30px
        td
          padding: 30px 16px 16px 16px

    .retailer-order-table__cell.table-border-b-none
      border-bottom: none

    .brand-order-table__cell.table-border-b-none.text-right
      text-align: right

    .brand-order-table__cell.table-border-b-none
      border-bottom: none

    .retailer-order-table__cell.table-border-b-none.text-right
      text-align: right

    .retailer-order-table.table-border-t-none
      padding-top: 46px
      margin-top: 10px
      //border-top: none


  &-navigation
    font-size: 14px
    color: #4A90E2

    span:first-child
      padding-right: 20px

  &-id
    padding-top: 10px
    padding-bottom: 10px
    font-size: 28px

  &-table
    border: 1px solid #E0E0E0
    padding: 20px

    &__top
      display: flex
    &__name
      color: #333333
      font-size: 24px
      font-weight: 500
      letter-spacing: 0.25px
      margin-bottom: 30px
      margin-left: 10px
    &__edit
      color: #262626
      margin-left: 30px
      font-size: 14px
      line-height: 35px
      text-decoration: underline
      cursor: pointer

    &__head
      color: #888888 !important
      font-size: 14px
      font-weight: 500
      border-bottom: 1px solid #E0E0E0 !important

    &__cell
      color: #333333 !important
      font-size: 14px
      font-weight: 500
      border-bottom: 1px solid #E0E0E0 !important
      margin-top: 10px
      position: relative

    &__container
      margin-right: 20px

  &-information
    border: 1px solid #E0E0E0

    &__title
      color: #333333
      font-size: 16px
      padding-bottom: 20px

    &__container
      padding: 20px
      border-bottom: 1px solid #E0E0E0

    &__link
      color: #006FBB
      font-weight: 600
      font-size: 14px

    &__header
      text-transform: uppercase
      color: #888888
      font-size: 12px
      padding-bottom: 10px

    &__logo
      width: 30px
      height: 30px

    &__terms
      margin-top: 20px

  &-status-banner
    background-color: rgba(74, 138, 93, 0.11)
    //border: 1px solid #E0E0E0
    border-radius: 0 0 3px 3px
    //border-top: 3px solid #9A8A8A
    min-height: 112px
    width: 100%
    margin-bottom: 20px
    padding-left: 20px

    &__title
      padding-top: 4px
      font-size: 16px
      color: #212B36

    &__details
      font-size: 14px
      color: #212B36

    &__icon
      display: flex
      padding-right: 10px

  &-calculations

    &__subtotal
      display: flex
      justify-content: flex-end
      padding-bottom: 20px

      &-amount
        padding-left: 45px

    &__full-subtotal
      display: flex
      justify-content: flex-end
      border-top: 1px solid #E0E0E0
      padding-top: 15px

      &-amount
        padding-left: 45px
        border-top: 1px solid #E0E0E0
        padding-top: 15px

    &__full-subtotal-title
      font-weight: bold
      color: #333333
  .retailer-order-information__container
    .shipping-first-block
      width: 100%
      .hipping-first-block__heading
        display: flex
        align-items: center
        .retailer-order-information__title
          padding-bottom: 0px
        .img-block
          height: 30px
          margin-left: auto
          img
            height: 30px

    .retailer-order-information__header
      color: #888
      .adresss
        padding-top: 16px
        p
          color: #333333
          font_size: 14px

  .order-product-item__dropdown
    position: absolute
    width: 128px
    top: 10px
    right: 10px
    z-index: 100
    background-color: #ffffff
    border: 1px solid #DADADA

  .order-product-item__dropdown-item
    height: 45px
    display: flex
    align-items: center
    padding-left: 10px
    cursor: pointer

  .order-product-item__dropdown-icon
    padding-right: 15px

  .order-product-item__dropdown-item:hover
    background-color: #F5F6F8

  .order-product-item__remove-button
    color: #BF0711

  .order-product-item__circles
    position: absolute
    top: 15px
    right: 0px
    z-index: 1
    cursor: pointer
    height: 30px
    width: 30px

  .alignTextCenter
    text-align: center !important
  .edit-shipment-info
    margin-left: auto
    text-decoration: underline
    color: #333333 !important
    font-size: 14px
  .shipment-provider
    font-size: 14px
    margin-bottom: 17px
  .order-information-subheading
    display: flex
    span
      color: #000000
      font-size: 16px
  .button-new-color
    width: 153px
    background-color: #4a8a5d
    color: white
  .icon-tips-style
    font-size: 44px
    color: #7ACE93
  .total-amount-number
    padding: 20px 25px
    margin-top: 10px
    border: 1px solid #D9D9D9
    font-size: 14px
    font-weight: 700
    font-family: "Proxima Nova", "Roboto", sans-serif
    color: #000000
    text-align: right
    span
      margin-left: 40px
  .edit-params-product
    color: #7ACE93
    font-size: 14px
    margin-bottom: 0
  .edit-params-product-color
    color: #7ACE93
  .additional-charge-tips-text
    margin: 9px 0
    display: flex
    justify-content: flex-start
    align-items: center
  .download-estimated-payment
    font-size: 14px
    text-decoration: underline
    font-weight: normal
  .download-estimated-payment:hover
    color: white
    background-color: #4a8a5d
    padding: 5px 7px
    font-size: 14px
    text-decoration: none
    border-radius: 2px
    font-weight: normal
.delete-dialog-tips-admin
  .ant-btn-primary
    background-color: #ff6132 !important
    border-color: #ff6132 !important
.delete-dialog-tips-brand
  .ant-btn-primary
    background-color: #4a8a5d !important
    border-color: #4a8a5d !important
.delete-dialog-tips-retailer
  .ant-btn-primary
    background-color: #FEC206 !important
    border-color: #FEC206 !important
.sku-style
  width: 160px
</style>
